import React from "react";
import { Link } from "react-router-dom";
import "./Navbar.css";
import { MdPhoneIphone } from "react-icons/md";
import { FaWhatsapp, FaInstagram } from "react-icons/fa";
function Navbar() {
  return (
    <>
      <div
        className="container-fluid py-2 fixed-top  sticky-top scrollspy text-white"
        style={{ background: "#272626" }}
      >
        <div className="container ">
          <nav className="navbar navbar-expand-lg navbar-expand-md  navbar-dark">
            <a href="/" className="text-decoration-none">
              <div className="h3 d-flex ">
                <span className="log2 align-self-center font-weight-bolder">
                  GMT
                </span>
                <span className="log align-self-center font-weight-bold">
                  software
                </span>
              </div>
            </a>

            <button
              className="navbar-toggler collapsed border-0"
              type="button"
              data-toggle="collapse"
              data-target="#navbarNavDropdown"
              aria-controls="navbarNavDropdown"
              aria-expanded="false"
              aria-label="Toggle navigation"
            >
              <span className="navbar-toggler-icon"></span>
              <div className="close-icon py-1 text-white">x</div>
            </button>
            <div
              className="collapse navbar-collapse justify-content-end"
              id="navbarNavDropdown"
            >
              <ul className="navbar-nav">
                <li className="nav-item active">
                  <a className="nav-link text-white" href="/">
                    Home <span className="sr-only">(current)</span>
                  </a>
                </li>

                <li className="nav-item dropdown">
                  <a
                    className="nav-link text-white dropdown-toggle"
                    href="#"
                    id="navbarDropdownMenuLink"
                    data-toggle="dropdown"
                    aria-haspopup="true"
                    aria-expanded="false"
                  >
                    Bootcamp Courses
                  </a>
                  <div
                    className="dropdown-menu"
                    aria-labelledby="navbarDropdownMenuLink"
                  >
                    <a
                      href="/FrontEnd-Software-development-training-in-Abuja"
                      className="dropdown-item"
                    >
                      FrontEnd Web Development
                    </a>
                    <div className="dropdown-divider"></div>
                    <a
                      className="dropdown-item"
                      href="/Digital-Marketing-training-in-Abuja"
                    >
                      Digital Marketing
                    </a>

                    {/* <div className="dropdown-divider"></div>
                    <Link className="dropdown-item" to="/React">
                      React Development
                    </Link> */}
                    <div className="dropdown-divider"></div>
                    <a
                      className="dropdown-item"
                      href="/DataAnalysis-training-in-abuja"
                    >
                      Data Analytic Training
                    </a>
                    <div className="dropdown-divider"></div>
                    <a
                      className="dropdown-item"
                      href="/Python-Programming-training-in-Abuja"
                    >
                      Python Programming(Django)
                    </a>
                    <div className="dropdown-divider"></div>
                    <a
                      className="dropdown-item"
                      href="/Web-development-training-in-Abuja"
                    >
                      Software Engineering/Full Stack
                    </a>
                    <div className="dropdown-divider"></div>
                    <a
                      className="dropdown-item"
                      href="/Mobile-Application-Training-in-Abuja"
                    >
                      Mobile Application Development
                    </a>
                    <div className="dropdown-divider"></div>
                    <a
                      className="dropdown-item"
                      href="/UI/UX-designer-Training-in-Abuja"
                    >
                      UX/UI design
                    </a>
                    <div className="dropdown-divider"></div>
                    <a
                      className="dropdown-item"
                      href="/Cybersecurity-training-in-Abuja"
                    >
                      Cybersecurity
                    </a>
                    <div className="dropdown-divider"></div>
                    <a
                      className="dropdown-item"
                      href="/Microsoft-Office-training-in-Abuja"
                    >
                      Fundamental Computer Literacy
                    </a>
                    <div className="dropdown-divider"></div>
                    <a
                      className="dropdown-item"
                      href="/Software-training-institute-in-Abuja"
                    >
                      .Net Technologies
                    </a>
                    <div className="dropdown-divider"></div>
                    <a
                      className="dropdown-item"
                      href="/blockchain-and-cryptocurrency-training-in-abuja-nigeria"
                    >
                      Blockchain and cryptocurrency
                    </a>
                    <div className="dropdown-divider"></div>
                    <a className="dropdown-item" href="/Java-Training-in-Abuja">
                      Java Technologies
                    </a>
                    <div className="dropdown-divider"></div>
                    <a
                      className="dropdown-item"
                      href="/Devops-Training-in-abuja"
                    >
                      Dev-ops
                    </a>
                    <div className="dropdown-divider"></div>
                    <a
                      className="dropdown-item"
                      href="/Robotic-training-in-Abuja"
                    >
                      Robotics
                    </a>
                  </div>
                </li>
                <li className="nav-item dropdown">
                  <a
                    className="nav-link text-white dropdown-toggle"
                    href="#"
                    id="navbarDropdownMenuLink"
                    data-toggle="dropdown"
                    aria-haspopup="true"
                    aria-expanded="false"
                  >
                    Other Pages
                  </a>
                  <div
                    className="dropdown-menu"
                    aria-labelledby="navbarDropdownMenuLink"
                  >
                    <a href="/careers" className="dropdown-item">
                      Career Page
                    </a>
                    <div className="dropdown-divider"></div>
                    <a className="dropdown-item" href="/Certificate">
                      Verify Certificate
                    </a>

                    {/* <div className="dropdown-divider"></div>
                    <Link className="dropdown-item" to="/React">
                      React Development
                    </Link> */}
                    <div className="dropdown-divider"></div>
                    <a
                      className="dropdown-item"
                      href="/Computer-training-school-in-Abuja"
                    >
                      About Us
                    </a>
                  </div>
                </li>
                {/* <li className="nav-item">
                  <Link className="nav-link text-white" to="/curriculum">
                    Curriculum
                  </Link>
                </li> */}
                {/* <li className="nav-item">
                  <Link className="nav-link text-white" to="/Companies">
                    For Companies
                  </Link>
                </li> */}
                <li className="nav-item">
                  <a
                    className="nav-link text-white"
                    href="/Apply-for-GMTsoftware-Training"
                  >
                    Apply Now
                  </a>
                </li>
                <li className="nav-item">
                  <a
                    className="nav-link text-white"
                    href="/GMTsoftware-gallery"
                  >
                    Gallery
                  </a>
                </li>
                {/* <li className="nav-item dropdown">
                  <Link
                    className="nav-link text-white dropdown-toggle"
                    to="#"
                    id="navbarDropdownMenuLink"
                    data-toggle="dropdown"
                    aria-haspopup="true"
                    aria-expanded="false"
                  >
                    Blog
                  </Link>
                  <div
                    className="dropdown-menu"
                    aria-labelledby="navbarDropdownMenuLink"
                  >
                    <Link className="dropdown-item" to="/Blog">
                      All Blogs
                    </Link>
                    <div className="dropdown-divider"></div>
                    <Link className="dropdown-item" to="/Fullstackblog">
                      MERN STACK / FullStack
                    </Link>
                    <div className="dropdown-divider"></div>
                    <Link className="dropdown-item" to="/Pythonblog">
                      Python & Data Science blog
                    </Link>
                    <div className="dropdown-divider"></div>
                    <Link className="dropdown-item" to="/Flutterblog">
                      Flutter blog
                    </Link>
                    <div className="dropdown-divider"></div>

                    <Link className="dropdown-item" to="/UI/UXblog">
                      UX/UI design blog
                    </Link>
                    <div className="dropdown-divider"></div>
                  </div>
                </li> */}
                <li className="nav-item">
                  <a className="nav-link text-white" href="/portal">
                    Student portal
                  </a>
                </li>
              </ul>
            </div>
            <div className="contact_us">
              <MdPhoneIphone size={20} color="#fff" />
              08160197959 OR 09124041937
            </div>
            <div className="contact_us">
              <a
                style={{ color: "white" }}
                className="m-2"
                href="https://wa.me/2349124041937"
                target="_blank"
                rel="noopener noreferrer"
              >
                <FaWhatsapp size={30} color="#00ff00" />
                WhatsApp
              </a>

              {/* <Link
                className="m-2"
                to={
                  "//https://wa.me/09124041937?text=I'm%20inquiring%20about%20your%training/"
                }
                target="_blank"
                aria-label="whatsapp"
              >
                <FaWhatsapp size={30} color="#00ff00" />
              </Link> */}
            </div>
            <Link
              style={{ color: "white" }}
              className="m-2"
              to={"//www.instagram.com/gmtsoftware/"}
              target="_blank"
              aria-label="Instagram"
            >
              <FaInstagram size={30} color="#E1306C" />
              Instagram
            </Link>
          </nav>
        </div>
      </div>
    </>
  );
}

export default Navbar;
